* {
    scrollbar-width: 8px;
    scrollbar-color: #639dff transparent;
}

*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: #639dff;
    border-radius: 20px;
    border: 3px solid transparent;
}